import React from 'react';
import ReactDOM from 'react-dom';

// import * as serviceWorker from './serviceWorker';
import { Root } from './containers/Root';

import './index.css';

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

const DSN = process.env.REACT_APP_SENTRY_DSN;
if (DSN) {
    import('@sentry/browser')
        .then((Sentry) => Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN }))
        .catch((err) => console.warn('Failed to initialize Sentry.', err));
}
//
// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker
//         .register('/firebase-messaging-sw.js')
//         .then((registration) => console.log('Registration successful, scope is:', registration.scope))
//         .catch((err) => console.log('Service worker registration failed, error:', err));
// }
