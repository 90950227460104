import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

import { pipe } from 'fp-ts/es6/function';

import { createStyles, makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import { LOCATION_CAPTION } from '../utils/constants';

import { withTitle } from '../containers/WithTitle';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 400,
            fontSize: 18
        },
    })
);

const NotFound: FunctionComponent = () => {
    const styles = useStyles();
    const { push } = useHistory();
    return (
        <div className={styles.root}>
            <p>Такой страницы не существует</p>
            <Button color="primary" variant="contained" onClick={() => push('/')}>
                На главную
            </Button>
        </div>
    );
};

const Component = pipe(NotFound, withTitle(LOCATION_CAPTION.NOT_FOUND));
export { Component as NotFound };
