import React, { FunctionComponent } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { pipe } from 'fp-ts/es6/function';

import { LOCATION_CAPTION } from '../utils/constants';

import { HelpCrunchProvider } from '../providers/HelpCrunchProvider';

import { withTitle } from '../containers/WithTitle';
import { Feedback } from '../components/Feedback';
import { Content } from '../containers/Content';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: 'white',
            boxShadow: '0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12)',
            borderRadius: '0 0 4px 4px',
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridGap: 16,
            padding: 32,
            [theme.breakpoints.down('md')]: {
                padding: 20,
            },
        },
    })
);

const Help: FunctionComponent = () => {
    const classes = useStyles();

    return (
        <Content>
            <HelpCrunchProvider>
                <div className={classes.root}>
                    <Feedback type="chat" />
                    <Feedback type="feedback" />
                    <Feedback type="callRequest" />
                </div>
            </HelpCrunchProvider>
        </Content>
    );
};

const Component = pipe(Help, withTitle(LOCATION_CAPTION.HELP));
export { Component as Help };
