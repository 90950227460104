import { BearerAuth } from './client/auth';
import { ApiClientClass } from './client';
import { Protocol } from './protocol';
import { buildApiUrl } from '../utils/strings';
import { ContractEntity } from '../types/contract';

export const Auth = BearerAuth(null);
export const ApiClient = new ApiClientClass(Auth);

// ----- Methods -----
/* eslint-disable @typescript-eslint/explicit-function-return-type */

export const apiLogin = (payload: Protocol.LoginRequest) =>
    ApiClient.post<Protocol.LoginResponse, Protocol.LoginRequest>('/api/auth/token/login', payload);

export const apiContractDetails = (payload: Protocol.ContractCommonRequest) =>
    ApiClient.get<Protocol.ContractCommonResponse, null>(
        buildApiUrl('/api/contract/{id}/{billing}/dashboard', payload),
        null
    );

export const apiContractParams = (payload: Protocol.ContractParamsRequest) =>
    ApiClient.get<Protocol.ContractParamsResponse, null>(
        buildApiUrl('/api/contract/{id}/{billing}/params', payload),
        null
    );

export const apiContractTariffList = (payload: Protocol.ContractTariffsRequest) =>
    ApiClient.get<Protocol.ContractTariffsResponse, null>(
        buildApiUrl('/api/contract/{id}/{billing}/tariffs', payload),
        null
    );

export const apiContractServiceList = (payload: Protocol.ContractServicesRequest) =>
    ApiClient.get<Protocol.ContractServicesResponse, null>(
        buildApiUrl('/api/contract/{id}/{billing}/serviceList', payload),
        null
    );

export const apiContractPointList = (payload: Protocol.ContractPointRequest) =>
    ApiClient.get<Protocol.ContractPointResponse, null>(
        buildApiUrl('/api/contract/{id}/{billing}/pointList', payload),
        null
    );

export const apiNews = (payload: Protocol.NewsRequest) =>
    ApiClient.get<Protocol.NewsResponse, null>(buildApiUrl('/api/contract/{id}/{billing}/news', payload), null);

export const apiChangeContractPassword = (payload: ContractEntity & Protocol.ChangeContractPasswordRequest) =>
    ApiClient.post<Protocol.ChangeContractPasswordResponse, Protocol.ChangeContractPasswordRequest>(
        buildApiUrl('/api/contract/{id}/{billing}/changeContractPassword', payload),
        { oldpassword: payload.oldpassword, newpassword: payload.newpassword }
    );

export const apiChangePPPOEPassword = (payload: ContractEntity & Protocol.ChangePPPOEPasswordRequest) =>
    ApiClient.post<Protocol.ChangePPPOEPasswordResponse, Protocol.ChangePPPOEPasswordRequest>(
        buildApiUrl('/api/contract/{id}/{billing}/changePPPoEPassword', payload),
        {
            oldpassword: payload.oldpassword,
            newpassword: payload.newpassword,
            idservice: payload.idservice,
        }
    );

export const apiContractBalance = (payload: Protocol.ContractBalanceRequest) =>
    ApiClient.get<Protocol.ContractBalanceResponse, null>(
        buildApiUrl('/api/contract/{id}/{billing}/balance/{yearFrom}/{monthFrom}/{yearTo}/{monthTo}', payload),
        null
    );

export const apiIncomingList = (payload: Protocol.IncomingRequest) =>
    ApiClient.get<Protocol.IncomingResponse, null>(
        buildApiUrl(
            '/api/contract/{id}/{billing}/incoming/{phones}/{year}/{month}/{dayFrom}/{dayTo}/{pageSize}/{pageNumber}',
            payload
        ),
        null
    );

export const apiContractTrafficByService = (payload: Protocol.ContractTrafficByServiceRequest) =>
    ApiClient.get<Protocol.ContractTrafficByServiceResponse, null>(
        buildApiUrl('/api/contract/{id}/{billing}/traffic/{serviceId}/{year}/{month}/{pageSize}/{pageNumber}', payload),
        null
    );

export const apiOutcomingList = (payload: Protocol.OutcomingRequest) =>
    ApiClient.get<Protocol.OutcomingResponse, null>(
        buildApiUrl(
            '/api/contract/{id}/{billing}/outcoming/{phones}/{year}/{month}/{dayFrom}/{dayTo}/{isPay}/{pageSize}/{pageNumber}',
            payload
        ),
        null
    );

export const apiAttachContract = (payload: ContractEntity & Protocol.AddLinkRequest) =>
    ApiClient.post<Protocol.AddLinkResponse, Protocol.AddLinkRequest>(
        buildApiUrl('/api/contract/{id}/{billing}/addLink', payload),
        {
            login: payload.login,
            password: payload.password,
        }
    );

export const apiDetachContract = (contract: ContractEntity, payload: Protocol.CloseLinkRequest) =>
    ApiClient.post<Protocol.CloseLinkResponse, Protocol.CloseLinkRequest>(
        buildApiUrl('/api/contract/{id}/{billing}/closeLink', contract),
        {
            billing: payload.billing,
            contract: payload.contract,
        }
    );

export const apiRefreshToken = (contract: ContractEntity) =>
    ApiClient.post<Protocol.RefreshTokenResponse, null>(
        buildApiUrl('/api/contract/{id}/{billing}/refreshToken', contract),
        null
    );

export const apiGetContractLimitHistory = (payload: Protocol.ContractLimitHistoryRequest) =>
    ApiClient.get<Protocol.ContractLimitHistoryResponse, null>(
        buildApiUrl('/api/contract/{id}/{billing}/limit/{year}/{month}/{pageSize}/{pageNumber}', payload),
        null
    );

export const apiSberbankDoPay = (payload: ContractEntity & Protocol.SberbankDoPayRequest) =>
    ApiClient.post<Protocol.SberbankDoPayResponse, Protocol.SberbankDoPayRequest>(
        buildApiUrl('/api/contract/{id}/{billing}/sberbankDoPay', payload),
        {
            email: payload.email,
            summa: payload.summa,
        }
    );

export const apiPayonlineDoPay = (payload: ContractEntity & Protocol.PayonlineDoPayRequest) =>
    ApiClient.post<Protocol.PayonlineDoPayResponse, Protocol.PayonlineDoPayRequest>(
        buildApiUrl('/api/contract/{id}/{billing}/payOnlineDoPay', payload),
        {
            email: payload.email,
            summa: payload.summa,
            redirectURL: payload.redirectURL,
        }
    );

export const apiSberbankPaymentHistory = (payload: Protocol.SberbankHistoryRequest) =>
    ApiClient.get<Protocol.SberbankHistoryResponse, null>(
        buildApiUrl('/api/contract/{id}/{billing}/sberbank/{year}/{month}/{pageSize}/{pageNumber}', payload),
        null
    );

export const apiPayonlinePaymentHistory = (payload: Protocol.PayonlineHistoryRequest) =>
    ApiClient.get<Protocol.PayonlineHistoryResponse, null>(
        buildApiUrl('/api/contract/{id}/{billing}/payOnline/{year}/{month}/{pageSize}/{pageNumber}', payload),
        null
    );

export const apiChangeContractLimit = (contract: ContractEntity, payload: Protocol.ChangeContractLimitRequest) =>
    ApiClient.post<Protocol.ChangeContractLimitResponse, Protocol.ChangeContractLimitRequest>(
        buildApiUrl('/api/contract/{id}/{billing}/changeContractLimit', contract),
        payload
    );

export const apiDoAutoReplenishment = (payload: ContractEntity & Protocol.AutoReplenishmentRequest) =>
    ApiClient.post<Protocol.AutoReplenishmentResponse, Protocol.AutoReplenishmentRequest>(
        buildApiUrl('/api/contract/{id}/{billing}/payOnlineDoAutoPay', payload),
        {
            day: payload.day,
            email: payload.email,
            summa: payload.summa,
        }
    );

export const apiAutoReplenishmentInfo = (payload: Protocol.AutoReplenishmentInfoRequest) =>
    ApiClient.get<Protocol.AutoReplenishmentInfoResponse, null>(
        buildApiUrl('/api/contract/{id}/{billing}/payOnlineAutoPay', payload),
        null
    );

export const apiDeleteAutoReplenishment = (payload: Protocol.AutoReplenishmentDeleteRequest) =>
    ApiClient.get<Protocol.AutoReplenishmentDeleteResponse, null>(
        buildApiUrl('/api/contract/{id}/{billing}/payOnlineDeleteAutoPay', payload),
        null
    );

export const apiChangeTariff = (payload: ContractEntity & Protocol.TariffChangeRequest) =>
    ApiClient.post<Protocol.TariffChangeResponse, Protocol.TariffChangeRequest>(
        buildApiUrl('/api/contract/{id}/{billing}/contractTariffChangeByCustomer', payload),
        {
            tariffGroupId: payload.tariffGroupId,
            fromContractTariffId: payload.fromContractTariffId,
            toTariffPlanId: payload.toTariffPlanId,
            fromDate: payload.fromDate,
        }
    );

export const apiChangeTariffAvailableDates = (payload: Protocol.TariffChangeAvailableDatesRequest) =>
    ApiClient.get<Protocol.TariffChangeAvailableDatesResponse, null>(
        buildApiUrl(
            '/api/contract/{id}/{billing}/contractTariffChangeAvailableDateList/{gid}/{tariffFromId}/{tariffToId}',
            payload
        ),
        null
    );

export const apiChangeTariffCancel = (payload: ContractEntity & Protocol.TariffChangeCancelRequest) =>
    ApiClient.post<Protocol.TariffChangeCancelResponse, Protocol.TariffChangeCancelRequest>(
        buildApiUrl('/api/contract/{id}/{billing}/contractTariffCancelByCustomer', payload),
        {
            contractTariffId: payload.contractTariffId,
            replacedFrom: payload.replacedFrom,
        }
    );

export const apiGetLimitSettings = (contract: ContractEntity) =>
    ApiClient.get<Protocol.LimitSettingsResponse, null>(
        buildApiUrl('/api/contract/{id}/{billing}/limitSettings', contract),
        null
    );

export const apiPayonlineGetPDF = (payload: Protocol.PayOnlineGetPDFRequest) =>
    ApiClient.get<Protocol.PayOnlineGetPDFResponse, null>(
        buildApiUrl('/api/contract/{id}/{billing}/payOnlineGetPDF/{transaction_id}', payload),
        null,
        'blob'
    );

export const apiGetQRCode = (contract: ContractEntity) =>
    ApiClient.get<Protocol.GetQRCodeResponse, null>(
        buildApiUrl('/api/contract/{id}/{billing}/getQRCode', contract),
        null,
        'blob'
    );

export const apiCreateFeedback = (payload: ContractEntity & Protocol.AddFeedbackRequest) =>
    ApiClient.post<Protocol.AddFeedbackResponse, Protocol.AddFeedbackRequest>(
        buildApiUrl('/api/contract/{id}/{billing}/addFeedbackRequest', payload),
        payload
    );

export const apiSetParamFullName = (payload: ContractEntity & Protocol.SetParamFullNameRequest) =>
    ApiClient.post<Protocol.SetParamFullNameResponse, Protocol.SetParamFullNameRequest>(
        buildApiUrl('/api/contract/{id}/{billing}/setContractParamFullName', payload),
        payload
    );

export const apiSetParamBirthday = (payload: ContractEntity & Protocol.SetParamBirthdayRequest) =>
    ApiClient.post<Protocol.SetParamBirthdayResponse, Protocol.SetParamBirthdayRequest>(
        buildApiUrl('/api/contract/{id}/{billing}/setContractParamBirthday', payload),
        payload
    );

export const apiSetParamPhones = (contract: ContractEntity, data: Protocol.SetParamPhonesRequest) =>
    ApiClient.post<Protocol.SetParamPhonesResponse, Protocol.SetParamPhonesRequest>(
        buildApiUrl('/api/contract/{id}/{billing}/setContractParamContactPhones', contract),
        data
    );

export const apiSetParamEmails = (contract: ContractEntity, data: Protocol.SetParamEmailsRequest) =>
    ApiClient.post<Protocol.SetParamEmailsResponse, Protocol.SetParamEmailsRequest>(
        buildApiUrl('/api/contract/{id}/{billing}/setContractParamEmails', contract),
        data
    );

export const apiRegisterPushToken = (payload: Protocol.RegisterPushTokenRequest) =>
    ApiClient.post<
        Protocol.RegisterPushTokenResponse,
        Pick<Protocol.RegisterPushTokenRequest, 'token'> & { platform: 'web' }
    >(buildApiUrl('/api/contract/{id}/{billing}/registerPushToken', { id: payload.id, billing: payload.billing }), {
        platform: 'web',
        token: payload.token,
    }).catch((e) => console.warn(e)); // NOTE: игнорируем ошибку

export const apiDeletePushToken = (payload: Protocol.DeletePushTokenRequest) =>
    ApiClient.post<Protocol.DeletePushTokenResponse, Pick<Protocol.DeletePushTokenRequest, 'token'>>(
        buildApiUrl('/api/contract/{id}/{billing}/deletePushToken', { id: payload.id, billing: payload.billing }),
        { token: payload.token }
    ).catch((e) => console.warn(e)); // NOTE: игнорируем ошибку

export const apiTrafficDownloadHTML = (payload: Protocol.TrafficDownloadHTMLRequest) =>
    ApiClient.get<Protocol.TrafficDownloadHTMLResponse, Protocol.TrafficDownloadHTMLRequest>(
        buildApiUrl('/api/contract/{id}/{billing}/trafficDownloadHTML/{serviceId}/{year}/{month}', payload),
        payload,
        'html'
    );

export const apiIncomingDownloadHTML = (payload: Protocol.IncomingDownloadHTMLRequest) =>
    ApiClient.get<Protocol.IncomingDownloadHTMLResponse, Protocol.IncomingDownloadHTMLRequest>(
        buildApiUrl(
            '/api/contract/{id}/{billing}/incomingDownloadHTML/{phones}/{year}/{month}/{dayFrom}/{dayTo}',
            payload
        ),
        payload,
        'html'
    );

export const apiOutcomingDownloadHTML = (payload: Protocol.OutcomingDownloadHTMLRequest) =>
    ApiClient.get<Protocol.OutcomingDownloadHTMLResponse, Protocol.OutcomingDownloadHTMLRequest>(
        buildApiUrl(
            '/api/contract/{id}/{billing}/outcomingDownloadHTML/{phones}/{year}/{month}/{dayFrom}/{dayTo}/{isPay}',
            payload
        ),
        payload,
        'html'
    );

/* eslint-enable @typescript-eslint/explicit-function-return-type */
