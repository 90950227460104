import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Header } from '../components/Header';
import { AsideInfo } from '../components/AsideInfo';
import { Navigation } from '../components/Navigation';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        layout: {},
        container: {
            maxWidth: 1136,
            padding: '32px 16px',
            margin: '0 auto',
            display: 'flex',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
            },
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            width: '72.5%',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        asideContainer: {
            paddingRight: 16,
            width: '27.5%',
            [theme.breakpoints.down('md')]: {
                marginTop: theme.spacing(2),
            },
            [theme.breakpoints.down('md')]: {
                marginBottom: 24,
                width: '100%',
            },
            [theme.breakpoints.down('sm')]: {
                marginBottom: 16,
            },
        },
    })
);

export const Layout: FunctionComponent = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.layout}>
            <Header />
            <main className={classes.container}>
                <section className={classes.asideContainer}>
                    <AsideInfo />
                </section>
                <section className={classes.content}>
                    <Navigation />
                    {props.children}
                </section>
            </main>
        </div>
    );
};
