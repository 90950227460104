import React, { FC, useState } from 'react';

import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment';

import { FormControl, InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { CalendarToday } from '@material-ui/icons';

import { ButtonWithLoading } from './ButtonWithLoading';

type DatesInterface = { from: Moment | null; to: Moment | null };

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: { margin: 0 },
        actionGroup: {
            marginTop: 16,
            display: 'flex',
        },
        fieldsGroup: {
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
                '& > :not(:last-child)': {
                    marginBottom: theme.spacing(1.5),
                },
            },
            '& > :not(:last-child)': {
                marginRight: theme.spacing(1.5),
                [theme.breakpoints.down('sm')]: {
                    marginRight: 0,
                },
            },
        },
    })
);

const usePickerFieldStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 300,
            [theme.breakpoints.down('md')]: {
                width: 282,
            },
            [theme.breakpoints.down('sm')]: {
                marginRight: 300,
            },
        },
        inputLabel: { color: '#616776' },
        inputRoot: {
            backgroundColor: '#EEF2F9',
            borderRadius: 4,
            '&:before': { display: 'none' },
            '&:after': { display: 'none' },
            '&:hover': { backgroundColor: '#e8f0ff' },
            '&:focus': { backgroundColor: '#e8f0ff' },
        },
    })
);

const PickerField: FC<TextFieldProps> = (props) => {
    const classes = usePickerFieldStyles();
    return (
        <TextField
            {...props}
            variant="filled"
            className={classes.root}
            InputLabelProps={{ classes: { root: classes.inputLabel } }}
            InputProps={{
                classes: { root: classes.inputRoot },
                endAdornment: (
                    <InputAdornment position="end">
                        <CalendarToday style={{ color: '#9898A6' }} />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export const VoluntaryLock: FC = () => {
    const classes = useStyles();

    const [dates, setDates] = useState<DatesInterface>({ from: moment(), to: null });
    const [loading, setLoading] = useState(false);

    const handleLockAction = async (): Promise<void> => {
        setLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 1500));
        setLoading(false);
    };

    return (
        <section>
            <p>
                Оказание услуги приостанавливается, деньги за использование не списываются.
                <br />
                Можно подключить обратно в любой момент.
            </p>
            <div className={classes.fieldsGroup}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="ru">
                    <FormControl>
                        <DatePicker
                            format="DD.MM.yyyy"
                            label="Дата начала"
                            okLabel={null}
                            autoOk
                            value={dates.from}
                            TextFieldComponent={PickerField}
                            minDate={moment().endOf('day')}
                            onChange={(newDate: MaterialUiPickersDate) => setDates((h) => ({ ...h, from: newDate }))}
                        />
                    </FormControl>
                    <FormControl>
                        <DatePicker
                            format="DD.MM.yyyy"
                            label="Дата окончания"
                            clearLabel="Очистить"
                            okLabel={null}
                            autoOk
                            value={dates.to}
                            TextFieldComponent={PickerField}
                            minDate={moment().add(1, 'day').endOf('day')}
                            onChange={(newDate: MaterialUiPickersDate) => setDates((h) => ({ ...h, to: newDate }))}
                        />
                    </FormControl>
                </MuiPickersUtilsProvider>
            </div>
            <div className={classes.actionGroup}>
                <ButtonWithLoading
                    color="primary"
                    onClick={handleLockAction}
                    loading={loading}
                    containerClass={classes.button}
                >
                    Подключить
                </ButtonWithLoading>
            </div>
        </section>
    );
};
