import React, { FunctionComponent, useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { pipe } from 'fp-ts/es6/pipeable';
import { isNonEmpty } from 'fp-ts/es6/Array';

import { Button, createStyles, LinearProgress, makeStyles } from '@material-ui/core';

import { useStore } from 'effector-react';
import { clearSession, refreshToken, SystemContractsStore, SystemStore } from './effector/system';
import { CurrentContractStore, fetchContractParams, fetchDashboard } from './effector/dashboard';
import { fetchLimitSettings } from './effector/limits';

import { LOCATION_ROUTE } from './utils/constants';
import { isArray } from './utils/guards';

import { PrivateRoute } from './router/PrivateRoute';

import { FeaturedLayout } from './containers/FeaturedLayout';
import { Layout } from './containers/Layout';

import { Dashboard } from './views/Dashboard';
import { Advanced } from './views/Advanced';
import { NotFound } from './views/NotFound';
import { Settings } from './views/Settings';
import { Login } from './views/Login';
import { News } from './views/News';
import { Help } from './views/Help';

const RootPath = process.env.PUBLIC_URL || '/';

const useStyles = makeStyles(() =>
    createStyles({
        emptyContract: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        emptyContractText: {
            textAlign: 'center',
            width: '100%',
            marginTop: '1rem',
            fontSize: '1.3rem',
            fontWeight: 'bold',
        },
        emptyContractLogout: {
            marginTop: '1.25rem',
            // NOTE: Copypaste from: src/components/modal/styles.ts:L55
            height: 48,
            fontSize: 14,
            '& > div': {
                height: '100%',
            },
        },
    })
);

const App: FunctionComponent = () => {
    const classes = useStyles();

    const { loggedIn } = useStore(SystemStore);

    const contracts = useStore(SystemContractsStore);
    const currentContract = useStore(CurrentContractStore);

    const [loading, setLoading] = useState(true);
    const [notEnoughContracts, setNotEnoughContracts] = useState(false);

    useEffect(() => {
        if (loggedIn) {
            const stack: Promise<unknown>[] = [];
            if (currentContract === null) {
                setNotEnoughContracts(false);
                if (isArray(contracts) && isNonEmpty(contracts)) {
                    const firstContract = contracts[0];
                    stack.push(fetchDashboard(firstContract));
                    stack.push(fetchContractParams(firstContract));
                    stack.push(fetchLimitSettings(firstContract));
                    stack.push(refreshToken(firstContract));
                } else {
                    setNotEnoughContracts(true);
                }
            }
            if (stack.length) Promise.all(stack).finally(() => setLoading(false));
            else setLoading(false);
        } else setLoading(false);
    }, [loggedIn, currentContract]); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading)
        return (
            <FeaturedLayout withLogo>
                <LinearProgress style={{ width: '100%', marginTop: '5em' }} />
            </FeaturedLayout>
        );

    if (loggedIn && notEnoughContracts)
        return (
            <FeaturedLayout withLogo>
                <div className={classes.emptyContract}>
                    <div className={classes.emptyContractText}>Нет привязанных договоров</div>
                    <Button
                        className={classes.emptyContractLogout}
                        onClick={() => clearSession()}
                        variant="contained"
                        color="primary"
                    >
                        Сменить аккаунт
                    </Button>
                </div>
            </FeaturedLayout>
        );

    return (
        <>
            <Router basename={RootPath}>
                <Switch>
                    <Route path="/" exact>
                        {loggedIn ? <Redirect to={LOCATION_ROUTE.DASHBOARD} /> : <Login />}
                    </Route>

                    <PrivateRoute path={LOCATION_ROUTE.DASHBOARD} exact>
                        <Layout>
                            <Dashboard />
                        </Layout>
                    </PrivateRoute>

                    <PrivateRoute path={LOCATION_ROUTE.ADVANCED} exact>
                        <Layout>
                            <Advanced />
                        </Layout>
                    </PrivateRoute>

                    <PrivateRoute path={LOCATION_ROUTE.SETTINGS} exact>
                        <Layout>
                            <Settings />
                        </Layout>
                    </PrivateRoute>

                    <PrivateRoute path={LOCATION_ROUTE.NEWS} exact>
                        <Layout>
                            <News />
                        </Layout>
                    </PrivateRoute>

                    <PrivateRoute path={LOCATION_ROUTE.HELP} exact>
                        <Layout>
                            <Help />
                        </Layout>
                    </PrivateRoute>

                    <PrivateRoute path="*">
                        <FeaturedLayout withLogo grayBg is404>
                            <NotFound />
                        </FeaturedLayout>
                    </PrivateRoute>
                </Switch>
            </Router>
        </>
    );
};

const app = pipe(App);
export { app as App };
