import React, { FunctionComponent, useState } from 'react';
import {
    Button,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import { Add, Delete, Phone, Language } from '@material-ui/icons';
import { useStore } from 'effector-react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AttachContractModal } from './AttachContractModal';
import { DetachContractModal } from './DetachContractModal';
import { CurrentContractStore, OriginalContractStore } from '../effector/dashboard';
import { SystemContractsStore } from '../effector/system';
import { ContractEntity } from '../types/contract';
import { FeipTypograf } from '../utils/typograf';

enum BillingType {
    // eslint-disable-next-line no-shadow
    Phone = 'phone',
    Internet = 'internet',
}

const ContractTypeCaptions = {
    [BillingType.Phone]: 'Телефон',
    [BillingType.Internet]: 'Интернет',
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        text: {
            color: theme.palette.text.secondary,
        },
    })
);

export const ContractsList: FunctionComponent = () => {
    const [isModalVisible, setModalVisible] = useState(false);
    const [isDialogVisible, setDialogVisible] = useState(false);
    const [contractToDelete, setContractToDelete] = useState<ContractEntity>({
        id: 0,
        status: '',
        comment: '',
        billing: 'phone',
        enableSberbank: false,
        enableLimit: false,
        enablePayOnline: false,
    });
    const currentContract = useStore(CurrentContractStore);
    const originalContract = useStore(OriginalContractStore);
    const contracts = useStore(SystemContractsStore).filter((contract) => contract.id !== currentContract?.id);
    const isDeleteVisible = originalContract?.id === currentContract?.id;

    const onDeleteClick = (contract: ContractEntity): void => {
        setContractToDelete(contract);
        setDialogVisible(true);
    };

    const classes = useStyles();

    return (
        <div style={{ width: '100%' }}>
            <p className={classes.text}>
                Если у вас заключено несколько договоров на обслуживание с ООО «ИнтерКамСервис», то Вы можете привязать
                их все к одному аккаунту для быстрого переключения между ними.
            </p>
            {contracts.length > 0 ? (
                <List>
                    {contracts.map((contract) => (
                        <ListItem key={contract.id}>
                            <ListItemAvatar>
                                <Avatar>
                                    {contract.billing === BillingType.Phone && <Phone />}
                                    {contract.billing === BillingType.Internet && <Language />}
                                </Avatar>
                            </ListItemAvatar>

                            <ListItemText
                                primary={`Договор ${FeipTypograf.execute(contract.title ?? contract.id.toString())}`}
                                secondary={ContractTypeCaptions[contract.billing]}
                            />
                            {isDeleteVisible && (
                                <ListItemSecondaryAction>
                                    <Tooltip title="Отвязать договор" enterDelay={0}>
                                        <IconButton
                                            edge="end"
                                            aria-label="Отвязать договор"
                                            onClick={() => onDeleteClick(contract)}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    ))}
                </List>
            ) : (
                <p>Присоединенных договоров нет</p>
            )}
            <Button
                variant="contained"
                color="primary"
                style={{ marginTop: 16 }}
                startIcon={<Add />}
                onClick={() => {
                    setModalVisible(true);
                }}
            >
                Присоединить договор
            </Button>
            {/* Modal with attachment form */}
            <AttachContractModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} />

            <DetachContractModal
                open={isDialogVisible}
                setOpen={setDialogVisible}
                contractToDelete={contractToDelete}
            />
        </div>
    );
};
