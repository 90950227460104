import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import { pipe } from 'fp-ts/es6/function';

import { createStyles, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { useStore } from 'effector-react';
import { CurrentDashboardStore, CurrentParamsStore, fetchContractParams, fetchDashboard } from '../effector/dashboard';

import { formatPhone, foldApiTimestampToDate } from '../utils/view';
import { LOCATION_CAPTION } from '../utils/constants';
import { FeipTypograf } from '../utils/typograf';

import { withTitle } from '../containers/WithTitle';

import { ChangeFullNameParam } from '../components/change-params/ChangeFullNameParam';
import { ChangeBirthdayParam } from '../components/change-params/ChangeBirthdayParam';
import { ChangePhonesParam } from '../components/change-params/ChangePhonesParam';
import { ChangeEmailsParam } from '../components/change-params/ChangeEmailsParam';
import { QRCode } from '../components/QRCode';
import { getStatusConfig } from '../utils/dashboardStatus';

enum ServiceStatus {
    Open = 'открыт',
    Closed = 'закрыт',
    Blocked = 'заблокирован',
}

enum ServiceState {
    On = 'включен',
    Off = 'отключен',
    Deleted = 'удален',
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            boxShadow: '0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12)',
            borderRadius: '0 0 4px 4px',
            overflow: 'hidden',
            [theme.breakpoints.down('md')]: {
                paddingLeft: 0,
                paddingRight: 0,
                width: '100%',
            },
        },
        section: {
            backgroundColor: 'white',
            padding: 32,
            paddingRight: 80,
            '&:not(:last-child)': {
                borderBottom: '1px solid #EAEAEA',
            },
            [theme.breakpoints.down('md')]: {
                padding: 20,
            },
        },
        heading: {
            color: theme.palette.text.primary,
            fontSize: 24,
            fontWeight: 500,
            lineHeight: 1,
            margin: 0,
            [theme.breakpoints.down('md')]: {
                fontSize: 20,
            },
        },
        list: {
            listStyle: 'none',
            margin: '24px 0 0',
            padding: 0,
        },
        listItem: {
            display: 'flex',
            '&:not(:last-child)': {
                marginBottom: 12,
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        listLabel: {
            color: theme.palette.text.secondary,
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 1.35,
            margin: 0,
            marginRight: 16,
            minWidth: 192,
        },
        listValue: {
            color: theme.palette.text.primary,
            fontSize: 16,
            lineHeight: 1.35,
            margin: 0,
        },
        listValueWithGutter: {
            marginRight: 6,
        },
        listValueWrapper: {
            alignItems: 'center',
            display: 'flex',
        },
        itemValue: {
            color: '#202020',
            fontSize: 16,
            lineHeight: 1.35,
            margin: 0,
        },
        itemDescription: {
            color: '#404040',
            fontSize: 14,
            lineHeight: 1,
            margin: '8px 0 0',
        },
        itemInnerList: {
            '&:not(:last-child)': {
                marginBottom: 12,
            },
        },
        withStatus: {
            paddingLeft: 24,
            position: 'relative',
            '&::before': {
                backgroundColor: '#808080',
                borderRadius: '50%',
                content: '""',
                height: 14,
                left: 0,
                position: 'absolute',
                top: 4,
                width: 14,
            },
        },
        withStatus_online: {
            '&::before': {
                backgroundColor: '#21BF73',
            },
        },
        withStatus_offline: {
            '&::before': {
                backgroundColor: '#D32F2F',
            },
        },
        withStatus_blocked: {
            '&::before': {
                backgroundColor: '#baa640',
            },
        },
        itemDescription_smallGutter: {
            marginTop: 4,
        },
        withStatus_right: {
            display: 'inline-block',
            paddingLeft: 0,
            paddingRight: 24,
            '&::before': {
                left: 'auto',
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
            },
        },
        qrCode: {
            display: 'flex',
            marginTop: 24,
        },
    })
);

const SkeletonText = (): JSX.Element => (
    <Skeleton variant="text" width={220} height={21} style={{ transform: 'scale(1, 0.8)' }} />
);

const formatContact = (value: string, description: string): string =>
    `${value} ${description ? ` (${description})` : ''}`;

const Dashboard: FunctionComponent = () => {
    const classes = useStyles();

    const store = useStore(CurrentParamsStore);
    const dashboard = useStore(CurrentDashboardStore);
    const fetchingParams = useStore(fetchContractParams.pending);
    const fetchingDashboard = useStore(fetchDashboard.pending);

    const loading = fetchingParams || fetchingDashboard || dashboard === null;

    const statusConfig = dashboard !== null ? getStatusConfig(dashboard.status) : null;

    // if (loading || fetching) return <div>Загрузка</div>;
    // if (dashboard === null) return <p>some empty text...</p>;

    return (
        <div className={classes.root}>
            <section className={classes.section}>
                <h2 className={classes.heading}>Детали договора</h2>
                <ul className={classes.list}>
                    <li className={classes.listItem}>
                        <h4 className={classes.listLabel}>Имя клиента</h4>

                        {loading ? (
                            <SkeletonText />
                        ) : (
                            <div className={classes.listValueWrapper}>
                                <p className={clsx([classes.listValue, classes.listValueWithGutter])}>
                                    {FeipTypograf.execute(store.fullName ?? '—')}
                                </p>

                                <ChangeFullNameParam />
                            </div>
                        )}
                    </li>

                    <li className={classes.listItem}>
                        <h4 className={classes.listLabel}>Телефон</h4>

                        {loading ? (
                            <SkeletonText />
                        ) : (
                            <div className={classes.listValueWrapper}>
                                <p
                                    className={clsx([classes.listValue, classes.listValueWithGutter])}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            store.contactPhones.length > 0
                                                ? store.contactPhones
                                                      .map((item) =>
                                                          formatContact(formatPhone(item.phone), item.comment)
                                                      )
                                                      .join('<br />')
                                                : '—',
                                    }}
                                />

                                <ChangePhonesParam />
                            </div>
                        )}
                    </li>

                    <li className={classes.listItem}>
                        <h4 className={classes.listLabel}>E-mail</h4>

                        {loading ? (
                            <SkeletonText />
                        ) : (
                            <div className={classes.listValueWrapper}>
                                <p
                                    className={clsx([classes.listValue, classes.listValueWithGutter])}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            store.contactEmails.length > 0
                                                ? store.contactEmails
                                                      .map((item) => formatContact(item.email, item.comment))
                                                      .join('<br />')
                                                : '—',
                                    }}
                                />

                                <ChangeEmailsParam />
                            </div>
                        )}
                    </li>

                    <li className={classes.listItem}>
                        <h4 className={classes.listLabel}>Дата рождения</h4>

                        {loading ? (
                            <SkeletonText />
                        ) : (
                            <div className={classes.listValueWrapper}>
                                <p className={clsx([classes.listValue, classes.listValueWithGutter])}>
                                    {store.birthday ? foldApiTimestampToDate(store.birthday) : 'Не указано'}
                                </p>

                                <ChangeBirthdayParam />
                            </div>
                        )}
                    </li>

                    <li className={classes.listItem}>
                        <h4 className={classes.listLabel}>Название договора</h4>

                        {loading ? (
                            <SkeletonText />
                        ) : (
                            <p className={classes.listValue}>{FeipTypograf.execute(dashboard?.title || '—')}</p>
                        )}
                    </li>

                    <li className={classes.listItem}>
                        <h4 className={classes.listLabel}>Комментарий</h4>

                        {loading ? (
                            <SkeletonText />
                        ) : (
                            <p className={classes.listValue}>{FeipTypograf.execute(dashboard?.comment || '—')}</p>
                        )}
                    </li>

                    <li className={classes.listItem}>
                        <h4 className={classes.listLabel}>Дата открытия</h4>

                        {loading ? (
                            <SkeletonText />
                        ) : (
                            <p className={classes.listValue}>
                                {dashboard?.dateFrom ? foldApiTimestampToDate(dashboard?.dateFrom) : '—'}
                            </p>
                        )}
                    </li>

                    <li className={classes.listItem}>
                        <h4 className={classes.listLabel}>Статус</h4>

                        {loading ? (
                            <SkeletonText />
                        ) : (
                            <p
                                className={clsx(
                                    classes.listValue,
                                    classes.withStatus,
                                    statusConfig?.type === 'online' && classes.withStatus_online,
                                    statusConfig?.type === 'offline' && classes.withStatus_offline,
                                    statusConfig?.type === 'blocked' && classes.withStatus_blocked
                                )}
                            >
                                {statusConfig?.text ?? '—'}
                            </p>
                        )}
                    </li>
                </ul>

                <div className={classes.qrCode}>
                    <QRCode />
                </div>
            </section>

            {dashboard?.inetServicesCurrentStatus && dashboard?.inetServicesCurrentStatus.length > 0 && (
                <section className={classes.section}>
                    <h2 className={classes.heading}>Состояние интернет-сервисов</h2>
                    {loading ? (
                        <ul className={classes.list}>
                            <li className={classes.itemInnerList}>
                                <SkeletonText />
                            </li>
                            <li className={classes.itemInnerList}>
                                <SkeletonText />
                            </li>
                        </ul>
                    ) : (
                        <ul className={classes.list}>
                            {dashboard?.inetServicesCurrentStatus?.map((item, index) => (
                                <li key={index} className={classes.itemInnerList}>
                                    <div
                                        className={clsx(
                                            classes.withStatus,
                                            item.status === ServiceStatus.Open &&
                                                item.state === ServiceState.On &&
                                                classes.withStatus_online,
                                            item.status === ServiceStatus.Open &&
                                                (item.state === ServiceState.Off ||
                                                    item.state === ServiceState.Deleted) &&
                                                classes.withStatus_offline,
                                            item.status === ServiceStatus.Blocked && classes.withStatus_blocked
                                        )}
                                    >
                                        <p className={`${classes.itemValue}`}>{FeipTypograf.execute(item.title)}</p>
                                        <p
                                            className={`${classes.itemDescription} ${classes.itemDescription_smallGutter}`}
                                        >
                                            {FeipTypograf.execute(item.state)}, {FeipTypograf.execute(item.status)}
                                        </p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </section>
            )}

            <section className={classes.section}>
                <h2 className={classes.heading}>Адреса</h2>
                <ul className={classes.list}>
                    {store.fizAddress && (
                        <li className={classes.listItem}>
                            <h4 className={classes.listLabel}>Физический адрес</h4>

                            {loading ? (
                                <SkeletonText />
                            ) : (
                                <p className={classes.listValue}>{FeipTypograf.execute(store.fizAddress)}</p>
                            )}
                        </li>
                    )}

                    {store.urAddress && (
                        <li className={classes.listItem}>
                            <h4 className={classes.listLabel}>Юридический адрес</h4>

                            {loading ? (
                                <SkeletonText />
                            ) : (
                                <p className={classes.listValue}>{FeipTypograf.execute(store.urAddress)}</p>
                            )}
                        </li>
                    )}

                    {store.postAddress && (
                        <li className={classes.listItem}>
                            <h4 className={classes.listLabel}>Почтовый адрес</h4>

                            {loading ? (
                                <SkeletonText />
                            ) : (
                                <p className={classes.listValue}>{FeipTypograf.execute(store.postAddress)}</p>
                            )}
                        </li>
                    )}
                </ul>
            </section>
        </div>
    );
};

const Component = pipe(Dashboard, withTitle(LOCATION_CAPTION.DASHBOARD));
export { Component as Dashboard };
