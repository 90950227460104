import Typograf from 'typograf';

import { reduce } from 'fp-ts/es6/Array';
import { pipe } from 'fp-ts/es6/function';

import { HTMLSpan } from '../components/HTMLSpan';

type Override = { from: string; to: string };
const SymbolOverrides: Override[] = [{ from: '-', to: '&#8209;' }];

class FeipTypografClass {
    public instance: typograf.Typograf;

    constructor(locale: Array<string> = ['ru', 'en-US']) {
        this.instance = new Typograf({ locale });

        this.instance.enableRule('common/nbsp/afterNumber');
    }

    execute(text: string): string {
        return this.instance.execute(text);
    }

    executeWithOverrides(text: string): string {
        const transformed = this.instance.execute(text);

        return pipe(
            SymbolOverrides,
            reduce(transformed, (target, { from, to }) => target.replace(from, to))
        );
    }

    executeAndWrap(text: string, className?: string): JSX.Element {
        return HTMLSpan({ className, content: this.executeWithOverrides(text) }) as JSX.Element;
    }
}

export const FeipTypograf = new FeipTypografClass();
