import React, { FunctionComponent } from 'react';
import { Card, CardContent, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';

import LogoImage from '../assets/logo.svg';
import Logo404Image from '../assets/logo-404.svg';
import BackgroundImage from '../assets/background.png';

const useStyles = makeStyles<Theme, { grayBg: boolean }>((theme) =>
    createStyles({
        root: ({ grayBg }) => ({
            position: 'relative',
            minHeight: '100vh',
            paddingTop: 40,
            paddingBottom: 40,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            '&::before': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                content: '""', // JSS hack
                backgroundColor: theme.palette.primary.main,
                backgroundImage: `url(${BackgroundImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom right',
                backgroundSize: 'cover',
                ...(grayBg ? { filter: 'grayscale(1)' } : {}),
            },
        }),
        logo: {
            width: 115,
            margin: '0 auto',
            // marginBottom: 8,
            display: 'block',
        },
        card: {
            width: 400,
            maxWidth: '100%',
            position: 'relative',
        },
        cardContent: {
            padding: 40,
            '&:last-child': {
                paddingBottom: 40,
            },
            [theme.breakpoints.down('md')]: {
                padding: 32,
                '&:last-child': {
                    paddingBottom: 32,
                },
            },
            [theme.breakpoints.down('sm')]: {
                padding: 24,
                '&:last-child': {
                    paddingBottom: 24,
                },
            },
        },
    }),
);

type FeaturedLayoutProps = {
    withLogo?: boolean;
    grayBg?: boolean;
    is404?: boolean
};

export const FeaturedLayout: FunctionComponent<FeaturedLayoutProps> = ({
    children,
    withLogo = false,
    grayBg = false,
    is404 = false,
}) => {
    const classes = useStyles({ grayBg });

    return (
        <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
            className={classes.root}
        >
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    {withLogo && (
                        <img
                            className={classes.logo}
                            src={is404 ? Logo404Image : LogoImage}
                            alt="SKTV Logo"
                        />
                    )}
                    {children}
                </CardContent>
            </Card>
        </Grid>
    );
};

