import { createDomain, createEvent } from 'effector';
import { clearSession } from './system';

export const ChatDomain = createDomain();

export const setChatInitState = createEvent<boolean>();
export const clearChatState = createEvent();

const initialState = false;

export const ChatStore = ChatDomain.createStore(initialState)
    .on(setChatInitState, (s, p) => p)
    .reset(clearChatState, clearSession);
