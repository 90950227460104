import React, { FunctionComponent, createContext, useState, useEffect } from 'react';
import { useStore } from 'effector-react';

import { ChatStore, setChatInitState } from '../effector/chat';
import { CurrentContractStore } from '../effector/dashboard';
import { makeContractHash } from '../types/contract';

type FunctionArguments<T> = T extends (...args: infer R) => unknown ? R : never;

type HelpCrunchUserCustomData = Record<string, any>;
type HelpCrunchUserData = {
    name: string;
    email: string;
    phone: number;
    company: string;
    user_id: string;
    security_hash: HelpCrunchUserCustomData;
};

type HelpCrunchUserDataPayload = Partial<HelpCrunchUserData>;

type HelpCrunchUserAuthData = {
    email: string;
    user_id: string;
};

type HelpCrunchInitializationData = {
    applicationId: number;
    applicationSecret: string;
    signature?: string;
    user?: HelpCrunchUserDataPayload;
};

/**
 * HelpCrunch widget provides you with small JavaScript API on your website.
 * With it you can control and customize your chat and widget behavior.
 *
 * See documentation:
 *
 * [JS API Methods](https://docs.helpcrunch.com/developers/methods)
 *
 * [JS API Events](https://docs.helpcrunch.com/developers/events)
 */
interface HelpCrunch {
    /** Launched methods (like a GTM) */
    q: Array<FunctionArguments<HelpCrunch>>;

    // --- API Methods ---

    /** Shows the chat widget (chat start button) after the initialization or after it was hidden by hideChatWidget. */
    (action: 'showChatWidget'): void;

    /** Hides the chat widget (chat start button) after it was displayed by showChatWidget. */
    (action: 'hideChatWidget'): void;

    /** Opens the HelpCrunch chat. Can be called without displaying the widget. */
    (action: 'openChat'): void;

    /** Closes the HelpCrunch chat after it was opened by openChat. */
    (action: 'closeChat'): void;

    /**
     * Initializes the HelpCrunch widget on your website page.
     * All web SDK methods that were called before this method will run after it finishes the initialization.
     *
     * You can take this method with all required parameters right from your HelpCrunch account at
     * Settings page → Set up & Customize → Website Widgets → Your app name → Setup
     */
    (action: 'init', domain: string, data: HelpCrunchInitializationData): void;

    /**
     * Use it to add or update current customer parameters, such as name, email, phone, company and
     * customData (an object with any random data you want to write).
     * If there is no user initialized for the current visitor, this method will create a new user and you will be
     * able to see it at the Contacts page in your HelpCrunch account. This method is useful when the user makes
     * any changes in his main attributes you want to write and you want to see it in your HelpCrunch account instantly.
     */
    (action: 'updateUser', data: HelpCrunchUserDataPayload, signature?: string, callback?: Noop): void;

    /**
     * With the help of userAuth method, you can authorize a customer.
     * This method requires two parameters – ‘email’ and ‘user_id’.
     * However, you can add other parameters as well – such as ‘name’, ‘company’, or ‘phone’.
     *
     * Please note that the user_id attribute must be unique for each user!
     * Make sure you're passing it as a string.
     *
     * Usually, this method comes in useful if you want to postpone authorization until after some triggers have been
     * activated or if your website has a single page with dynamically updated content (Single-Page Application).
     */
    (action: 'userAuth', data: HelpCrunchUserAuthData, callback?: Noop): void;

    /**
     * Updates current user’s custom data.
     * Custom data is an object with any random data you want to write and then use in your HelpCrunch account.
     * Read more about it in web SDK custom data section.
     * This method is useful when the user makes any changes in his custom attributes and you want to see it in your
     * HelpCrunch account instantly.
     */
    (action: 'updateUserData', data: HelpCrunchUserCustomData, signature?: string, callback?: Noop): void;

    /**
     * You can track any custom events the user does in your website and view his event history in HelpCrunch account.
     * Read more about it in the track custom events section.
     */
    (action: 'trackEvent', eventName: string): void;

    /**
     * Call setPhraseList if you want to launch a custom localization or custom chat texts.
     * By default there are 6 languages available and the chat will pick the localization corresponding to the browser language.
     * If that language is not found, chat will display the default one. Read more about it in the localization section.
     */
    (action: 'setPhraseList', phraseListName: string): void;

    /**
     * HelpCrunch has a proactive chat auto-message system to automatically address visitors and leads.
     * You can configure the auto-message rules in your HelpCrunch account or just call it from web SDK JS API.
     * Read more about it in the send proactive chat auto-messages with JavaScript API section.
     */
    (action: 'sendProactiveChatAutoMessage', autoMessageId: string): void;

    /** Sometimes you need to send your support agent a message on behalf of your website user on some custom user action. */
    (action: 'sendUserMessage', messageText: string): void;

    /** This method allows adding a prefilled message to a text area without sending it. */
    (action: 'typeUserMessage', messageText: string): void;

    /**
     * On some websites user can have multiple accounts and can login/logout to switch between them.
     * If you use user authentication mode you can do the same for your HelpCrunch widget,
     * so the data from one user account will not pass to another.
     */
    (action: 'logout', callback?: Noop): void;

    /**
     * On some webpages you might not want to interrupt users with chat notification sounds.
     * These might be some conference rooms, classrooms etc...
     * Adding this boolean method will prevent chat sounds from playing ono such pages.
     */
    (action: 'muteNotificationSound', state: boolean): void;

    /** Chat button position on page can be changed dynamically or statically with this API method. */
    (action: 'setChatButtonOffset', horizontal: number, bottom: number): void;

    // --- API Events ---

    (action: 'onReady', callback: Noop): void;
}

declare global {
    interface Window {
        HelpCrunch: HelpCrunch;
    }
}

const HelpCrunchAppSecret = process.env.REACT_APP_HELP_CRUNCH_APP_SECRET;
if (!window.HelpCrunch) {
    // @ts-ignore
    window.HelpCrunch = (...args: FunctionArguments<HelpCrunch>): void => {
        window.HelpCrunch.q.push(args);
    };

    window.HelpCrunch.q = [];

    if (HelpCrunchAppSecret) {
        const s = document.createElement('script');
        s.async = true;
        s.type = 'text/javascript';
        s.src = 'https://widget.helpcrunch.com/';

        document.body.appendChild(s);
    }
}

type HelpCrunchState = 'idle' | 'loading' | 'success' | 'failed';
export type HelpCrunchProviderContext = {
    chatInstance: HelpCrunch;
    chatState: HelpCrunchState;
};

const InitializeTimeout = 15000;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const HelpCrunchContext = createContext<HelpCrunchProviderContext>(null!);
export const HelpCrunchProvider: FunctionComponent = (props) => {
    const chatInstance = window.HelpCrunch;
    const [chatState, setState] = useState<HelpCrunchState>('idle');

    const currentContract = useStore(CurrentContractStore);
    const initialized = useStore(ChatStore);

    useEffect(() => {
        if (HelpCrunchAppSecret) {
            setState('loading');

            if (!initialized) {
                chatInstance('init', 'iks', {
                    applicationId: 1,
                    applicationSecret: HelpCrunchAppSecret,
                });

                const fallbackTimeout = setTimeout(() => {
                    setState('failed');
                    setChatInitState(false);
                }, InitializeTimeout);

                chatInstance('onReady', () => {
                    setState('success');
                    setChatInitState(true);
                    clearTimeout(fallbackTimeout);
                });
            } else {
                setState('success');
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (currentContract) {
            const payload: HelpCrunchUserDataPayload = {
                name: currentContract.title ? currentContract.title : makeContractHash(currentContract),
            };

            chatInstance('updateUser', payload);
        }
    }, [currentContract]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <HelpCrunchContext.Provider value={{ chatInstance, chatState }}>{props.children}</HelpCrunchContext.Provider>
    );
};
