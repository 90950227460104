import { ContractTariff } from './tariff';

export type BillingType = 'internet' | 'phone';

export type ContractEntity = {
    id: number;
    billing: BillingType;
    title?: string | null;
    comment: string;
    status: string;
    enableLimit: boolean; // TODO: Use enum mb; Make enableBlaBla not optional
    enablePayOnline: boolean;
    enableSberbank: boolean;
};

type InternetStatus = {
    title: string;
    status: string;
    state: string;
};

export type ContractDescription = {
    title: string;
    comment: string;
    dateFrom: DateTime.Timestamp;
    status: string;
    currentTariffs: ContractTariff[];
    balance: number | string;
    closeSumma: number | string;
    lastPayDate: DateTime.Timestamp;
    lastPaySumm: number | string;
    lastPayType: number | string;
    inetServicesCurrentStatus?: InternetStatus[];
};

export type ContactEmail = {
    email: string;
    comment: string;
};

export type ContactPhone = {
    index: number; // номер по порядку
    phone: string;
    comment: string;
};

declare const ContractHashTag: unique symbol;
export type ContractHash = Opaque<string, typeof ContractHashTag>;

export const makeContractHash = (contract: ContractEntity): ContractHash =>
    `${contract.id}-${contract.billing}` as ContractHash;
